<template>
  <div>
    <el-dialog
      title="物资初始化"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="类型" prop="billType">
              <el-radio-group v-model.number="form.billType">
                <el-radio :label="1" border>项目</el-radio>
                <el-radio :label="2" border>仓库</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目" v-if="form.billType==1" prop="projectId">
              <project-select
                v-model="form.projectId"
                :state="[1,2]"
                :canAdd="true"
                @addClick="()=>{this.projectItem = {}}"
              ></project-select>
            </el-form-item>
            <el-form-item label="仓库" v-if="form.billType==2" prop="storeId">
              <base-select
                info="Store"
                v-model="form.storeId"
                :canAdd="true"
                @addClick="()=>{this.storeItem = {}}"
              ></base-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="物资" prop="goodsId">
              <base-select
                info="Goods"
                v-model="form.goodsId"
                :canAdd="true"
                @addClick="()=>{this.goodsItem = {}}"
              ></base-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input
                type="number"
                v-model="form.quantity"
                placeholder="数量"
                clearable
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单价" prop="price">
              <el-input
                type="number"
                v-model="form.price"
                placeholder="单价"
                clearable
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="摘要">
          <el-input v-model="form.remark" placeholder="摘要" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)">保存关闭</el-button>
            <el-button type="success" @click="save(true)">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div>
      <add-project :item="projectItem" @success="(id)=>{this.form.projectId=id}"></add-project>
      <add-store :item="storeItem" @success="(id)=>{this.form.storeId=id}"></add-store>
      <add-goods :item="goodsItem" @success="(id)=>{this.form.goodsId=id }"></add-goods>
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";

import AddProject from "@/views/project/Item.vue";
import AddStore from "@/views/store/Item";
import AddGoods from "@/views/goods/Item";
export default {
  props: ["item"],
  components: {
    BaseSelect,
    ProjectSelect,
    AddProject,
    AddStore,
    AddGoods
  },
  data() {
    return {
      visible: false,
      form: {},
      rules: {
        billType: [{ required: true, message: "类型不能为空" }],
        storeId: [{ required: true, message: "仓库不能为空" }],
        projectId: [{ required: true, message: "项目不能为空" }],
        goodsId: [{ required: true, message: "物资不能为空" }],
        quantity: [
          {
            validator: this.validator,
            trigger: "blur"
          }
        ],
        price: [
          {
            validator: this.validator,
            trigger: "blur"
          }
        ]
      },

      projectItem: {},
      storeItem: {},
      goodsItem: {}
    };
  },
  watch: {
    item: {
      handler: function(value) {
        this.initForm();
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    validator(rule, value, callback) {
      if (value <= 0) {
        return callback(new Error("必须大于0"));
      }
      callback();
    },
    initForm() {
      this.form = {
        billType: 2,
        projectId: "",
        storeId: "",
        goodsId: "",
        quantity: 0,
        price: 0,
        remark: ""
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        let bill = { ...this.form };
        //1项目2仓库
        if (bill.billType == 1) {
          bill.locationId = bill.projectId;
        }
        if (bill.billType == 2) {
          bill.locationId = bill.storeId;
        }
        let loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        this.$post("GoodsInit/Add", bill)
          .then(r => {
            this.$message.success("操作成功！");
            this.$emit("success");
            if (isContinue) {
              this.initForm();
            } else {
              this.visible = false;
            }
          })
          .finally(() => {
            loading.close();
          });
      });
    }
  }
};
</script>

<style>
</style>