<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="物资">
        <base-select info="Goods" v-model="filter.goodsId"></base-select>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="仓库">
        <base-select info="Store" v-model="filter.storeId"></base-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-plus" @click="item = {}" type="primary">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="data" stripe border>
      <el-table-column label="物资" prop="goodsName" show-overflow-tooltip></el-table-column>
      <el-table-column label="类型" prop="billTypeName" width="80"></el-table-column>
      <el-table-column label="项目/仓库" prop="locationName" show-overflow-tooltip></el-table-column>
      <el-table-column label="数量" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.quantity|thousands}}</template>
      </el-table-column>
      <el-table-column label="单价" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.price|thousands}}</template>
      </el-table-column>
      <el-table-column label="金额" show-overflow-tooltip>
        <template slot-scope="scope">{{(scope.row.quantity*scope.row.price)|thousands}}</template>
      </el-table-column>
      <el-table-column label="摘要" prop="remark" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="65">
        <template slot-scope="scope">
          <el-tag type="danger" effect="dark" @click="remove(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 50, 100, 500]"
      @size-change="getData(1)"
      @current-change="getData()"
      :current-page.sync="pageIndex"
      :page-size.sync="pageSize"
      :total="pageTotal"
    ></el-pagination>
    <div>
      <add-item :item="item" @success="getData()"></add-item>
    </div>
  </div>
</template>

<script>
import setName from "@/common/setName";
import ProjectSelect from "@/components/ProjectSelect.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import AddItem from "@/views/system/GoodsInit/Add.vue";
export default {
  components: {
    ProjectSelect,
    BaseSelect,
    AddItem
  },
  data() {
    return {
      filter: {},
      data: [],
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      item: {}
    };
  },
  watch: {
    filter: {
      handler: function(v) {
        this.getData(1);
      },
      deep: true
    }
  },
  activated() {
    this.getData();
  },
  methods: {
    getData(pageIndex) {
      if (pageIndex) {
        this.pageIndex = pageIndex;
      }
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let params = {
        ...this.filter,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      this.$get("GoodsInit/GetAll", params)
        .then(r => {
          r.list.forEach(item => {
            //赋值仓库名称
            if (item.billType == 2) {
              item.billTypeName = "仓库";
              setName("Store", item, "locationId", "locationName");
            }
            //赋值项目名称
            if (item.billType == 1) {
              item.billTypeName = "项目";
              setName("Project", item, "locationId", "locationName");
            }
            //赋值物资名称
            setName("Goods", item, "goodsId", "goodsName");
          });
          this.data = r.list;
          this.pageTotal = r.pageTotal;
        })
        .finally(() => {
          loading.close();
        });
    },
    remove(row) {
      this.$confirm("是否删除此条初始化记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          this.$post("GoodsInit/Delete?id=" + row.id)
            .then(r => {
              this.$message.success("操作成功！");
              this.getData();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<style>
</style>